import dynamic from 'next/dynamic'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import React, { useState } from 'react'

import styles from './styles.module.scss'
import Image from '../../../Image'
import NotionSnippet from '../../../NotionSnippet'
import globalStyles from '../../styles/styles.module.scss'
import { NotionSnippetElement } from '../../../NotionSnippet/types'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const $ = require('jquery')
if (typeof window !== 'undefined' && $) {
    window.$ = window.jQuery = require('jquery')
}

const OwlCarousel = dynamic(async () => await import('react-owl-carousel'), {
    ssr: false,
})

export default function Brands(): JSX.Element {
    const [submitted, setSubmitted] = useState(true)

    const codesContent = ['brands-1', 'brands-2']

    return (
        <section className={styles.brands}>
            <div className={globalStyles.container}>
                <div className={styles.buttons}>
                    <button
                        className={`${styles.buttonsButton} ${
                            styles.buttonsPresented
                        } ${submitted ? styles.buttonsButtonActive : ''}`}
                        onClick={() => {
                            setSubmitted(true)
                        }}
                    >
                        <div
                            className={`${styles.buttonsPresentedOuter} ${
                                submitted ? styles.buttonsPresentedActive : ''
                            }`}
                        >
                            Представленные бренды
                        </div>
                    </button>
                    <button
                        className={`${styles.buttonsButton} ${
                            styles.buttonsSoon
                        } ${!submitted ? styles.buttonsSoonOuterActive : ''}`}
                        onClick={() => {
                            setSubmitted(false)
                        }}
                    >
                        <div className={`${styles.buttonsSoonOuter}`}>
                            Скоро будут
                        </div>
                    </button>
                </div>
                <div className={styles.carouselWrapper}>
                    <OwlCarousel
                        className={`${styles.carouselWrapperCarousel} owl-theme`}
                        loop
                        items={5}
                        margin={79}
                        nav={false}
                        dots={false}
                        navContainerClass={styles.owlNav}
                        navElement={
                            'button type="button" aria-label="Arrow" role="presentation"'
                        }
                        responsive={{
                            300: {
                                items: 2,
                                margin: 37,
                            },
                            1500: {
                                items: 5,
                                margin: 79,
                            },
                        }}
                    >
                        <div className={styles.carouselWrapperCarouselItem}>
                            <a
                                aria-label={'Komatsu'}
                                href="src/components/HomePage/components/Brands"
                            >
                                <Image
                                    width={100}
                                    height={50}
                                    alt={'Tekara'}
                                    src={'/assets/temp/1.png'}
                                    hideBlur={true}
                                />
                            </a>
                        </div>
                        <div className={styles.carouselWrapperCarouselItem}>
                            <a
                                aria-label={'Hitachi'}
                                href="src/components/HomePage/components/Brands"
                            >
                                <Image
                                    width={100}
                                    height={50}
                                    alt={'Tekara'}
                                    src={'/assets/temp/2.png'}
                                    hideBlur={true}
                                />
                            </a>
                        </div>
                        <div className={styles.carouselWrapperCarouselItem}>
                            <a
                                aria-label={'Deutz'}
                                href="src/components/HomePage/components/Brands"
                            >
                                <Image
                                    width={60}
                                    height={50}
                                    alt={'Tekara'}
                                    src={'/assets/temp/3.png'}
                                    hideBlur={true}
                                />
                            </a>
                        </div>
                        <div className={styles.carouselWrapperCarouselItem}>
                            <a
                                aria-label={'Blumaq'}
                                href="src/components/HomePage/components/Brands"
                            >
                                <Image
                                    width={100}
                                    height={50}
                                    alt={'Tekara'}
                                    src={'/assets/temp/4.png'}
                                    hideBlur={true}
                                />
                            </a>
                        </div>
                        <div className={styles.carouselWrapperCarouselItem}>
                            <a
                                aria-label={'Case'}
                                href="src/components/HomePage/components/Brands"
                            >
                                <Image
                                    width={100}
                                    height={50}
                                    alt={'Tekara'}
                                    src={'/assets/temp/5.png'}
                                    hideBlur={true}
                                />
                            </a>
                        </div>
                        <div className={styles.carouselWrapperCarouselItem}>
                            <a
                                aria-label={'Komatsu'}
                                href="src/components/HomePage/components/Brands"
                            >
                                <Image
                                    width={100}
                                    height={50}
                                    alt={'Tekara'}
                                    src={'/assets/temp/1.png'}
                                    hideBlur={true}
                                />
                            </a>
                        </div>
                    </OwlCarousel>
                </div>
            </div>
            <div
                className={`${globalStyles.containerWrapper} ${styles.containerWrapper}`}
            >
                <div className={globalStyles.container}>
                    <div className={styles.containerWrapperDescription}>
                        {codesContent.map((i, idx) => (
                            <NotionSnippet key={i} code={i}>
                                {(props: NotionSnippetElement) => (
                                    <>
                                        <div
                                            className={
                                                styles.containerWrapperDescriptionTitle
                                            }
                                            dangerouslySetInnerHTML={{
                                                __html: props.header,
                                            }}
                                        />
                                        <div
                                            className={`${
                                                styles.containerWrapperDescriptionContent
                                            } ${
                                                idx === 0
                                                    ? styles.containerWrapperDescriptionContentFirst
                                                    : ''
                                            }`}
                                            dangerouslySetInnerHTML={{
                                                __html: props.content,
                                            }}
                                        />
                                    </>
                                )}
                            </NotionSnippet>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}
