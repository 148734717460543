import React from 'react'

import styles from './styles.module.scss'
import Image from '../../../Image'
import globalStyles from '../../styles/styles.module.scss'

export default function Contacts(): JSX.Element {
    const data = [
        {
            image: '/assets/images/qr-mail.png',
            link: 'mailto:team@tekara.ru',
            title: 'team@tekara.ru',
        },
        {
            image: '/assets/images/qr-telegram.png',
            link: 'https://t.me/@TekaraBot',
            title: '@TekaraBot',
        },
        {
            image: '/assets/images/qr-whatsapp.png',
            link: 'https://wa.me/79299125385',
            title: '+7 (929) 912-5385',
        },
    ]

    return (
        <section>
            <div className={globalStyles.container}>
                <div className={styles.row}>
                    {data.map((i, idx) => (
                        <div key={`${idx}`} className={styles.rowItem}>
                            <Image
                                width={236}
                                height={236}
                                src={i.image}
                                alt={i.title}
                                aria-label={i.title}
                                hideBlur={true}
                            />
                            <div className={styles.rowItemInfo}>
                                <a href={i.link}>{i.title}</a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}
