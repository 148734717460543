import Link from 'next/link'
import React, { useMemo } from 'react'

import styles from './styles.module.scss'
import { parseNotionMenu } from '../../../../utils/parsers'
import NotionSnippet from '../../../NotionSnippet'
import globalStyles from '../../styles/styles.module.scss'
import { NotionSnippetElement } from '../../../NotionSnippet/types'
import { type IDatabase } from '@/lib/types'

export default function Footer({
    database,
}: {
    database?: IDatabase | null
}): JSX.Element {
    const menu = useMemo(() => parseNotionMenu(database), [database])

    return (
        <footer className={styles.footer}>
            <div
                className={`${globalStyles.containerWrapper} ${styles.containerWrapper}`}
            >
                <div className={globalStyles.container}>
                    <div className={styles.heading}>
                        <nav>
                            <ul>
                                {menu.map((i, idx) => (
                                    <li key={`footer_${idx}`}>
                                        {i.isExternal &&
                                        !i.url.includes('#') ? (
                                            <a href={i.url}>{i.title}</a>
                                        ) : (
                                            <Link
                                                href={i.url}
                                                scroll={!i.url.includes('#')}
                                            >
                                                <a>{i.title}</a>
                                            </Link>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </nav>
                        <div className={styles.works}>
                            <a href="tel:+74955325316">+7 (495) 532-53-16</a>
                            <span>Пн-Пт: 8:00-19:00</span>
                        </div>
                    </div>
                    <NotionSnippet code={'footer-description'}>
                        {(props: NotionSnippetElement) => (
                            <div
                                className={styles.description}
                                dangerouslySetInnerHTML={{
                                    __html: props.content,
                                }}
                            />
                        )}
                    </NotionSnippet>
                    <div className={styles.bottom}>
                        <Link href={'/kb/info/privacy'}>
                            <a>Политика конфиденциальности</a>
                        </Link>
                        <Link href={'/kb/info/agreement'}>
                            <a>Пользовательское соглашение</a>
                        </Link>
                        <span>© 2023 Текара, ООО</span>
                    </div>
                </div>
            </div>
        </footer>
    )
}
