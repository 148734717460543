import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'

import Header from '../../components/Header'
import Brands from '../../components/HomePage/components/Brands'
import Contacts from '../../components/HomePage/components/Contacts'
import Footer from '../../components/HomePage/components/Footer'
import Reasons from '../../components/HomePage/components/Reasons'
import Top from '../../components/HomePage/components/Top'
import Why from '../../components/HomePage/components/Why'
import { type IDatabase } from '@/lib/types'

const Development = dynamic(
    async () =>
        await import('../../components/HomePage/components/Development'),
    { ssr: false }
)
const Provider = dynamic(
    async () => await import('../../components/HomePage/components/Provider'),
    { ssr: false }
)
const Feedback = dynamic(
    async () => await import('../../components/HomePage/components/Feedback'),
    { ssr: false }
)

interface Props {
    database: IDatabase | null
}

export default function HomePage(props: Props): JSX.Element {
    const [isMounted, setIsMounted] = useState(false)
    const router = useRouter()

    useEffect(() => {
        if (router.route === '/') {
            setIsMounted(true)
        }

        if (router.asPath.startsWith('/#') && !isMounted) {
            setTimeout(() => {
                const $element = document.querySelector(
                    `#${router.asPath.replace('/#', '')}`
                )
                if ($element) {
                    $element.scrollIntoView()
                }

                setIsMounted(true)
            }, 200)
        }
    }, [router])

    return (
        <>
            <Header database={props.database} />
            <Top />
            <Why />
            <Development />
            <Brands />
            <Provider />
            <Reasons />
            <Feedback />
            <Contacts />
            <Footer database={props.database} />
        </>
    )
}
