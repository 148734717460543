import React from 'react'

import styles from './styles.module.scss'
import NotionSnippet from '../../../NotionSnippet'
import { NotionSnippetElement } from '../../../NotionSnippet/types'

export default function Why(): JSX.Element {
    const blocks = [
        {
            iconClass: styles.iconReliability,
            templateCode: 'tell-why-block-{{id}}',
        },
        {
            iconClass: styles.iconStandart,
            templateCode: 'tell-why-block-{{id}}',
        },
        {
            iconClass: styles.iconBasket,
            templateCode: 'tell-why-block-{{id}}',
        },
        {
            iconClass: styles.iconCompliance,
            templateCode: 'tell-why-block-{{id}}',
        },
        {
            iconClass: styles.iconInsurance,
            templateCode: 'tell-why-block-{{id}}',
        },
        {
            iconClass: styles.iconDelivery,
            templateCode: 'tell-why-block-{{id}}',
        },
        {
            iconClass: styles.iconLogistics,
            templateCode: 'tell-why-block-{{id}}',
        },
        {
            iconClass: styles.iconTerms,
            templateCode: 'tell-why-block-{{id}}',
        },
    ]

    return (
        <section className={styles.whySection}>
            <div className={styles.containerWrapper}>
                <div className={styles.container}>
                    <NotionSnippet code={'tell-why'}>
                        {(props: NotionSnippetElement) => (
                            <h2
                                className={styles.header}
                                dangerouslySetInnerHTML={{
                                    __html: props.header,
                                }}
                            />
                        )}
                    </NotionSnippet>
                    <div className={styles.row}>
                        {blocks.map((i, idx) => (
                            <NotionSnippet
                                key={`${i.templateCode.replace(
                                    '{{id}}',
                                    `${idx + 1}`
                                )}`}
                                code={`${i.templateCode.replace(
                                    '{{id}}',
                                    `${idx + 1}`
                                )}`}
                            >
                                {(props: NotionSnippetElement) => (
                                    <div className={styles.rowItem}>
                                        <div className={styles.rowItemContent}>
                                            <div
                                                className={`${styles.icon} ${i.iconClass}`}
                                            ></div>
                                            <div
                                                className={styles.title}
                                                dangerouslySetInnerHTML={{
                                                    __html: props.header,
                                                }}
                                            />
                                            <div
                                                className={styles.description}
                                                dangerouslySetInnerHTML={{
                                                    __html: props.content,
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </NotionSnippet>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}
