import React from 'react'

import styles from './styles.module.scss'
import NotionSnippet from '../../../NotionSnippet'
import globalStyles from '../../styles/styles.module.scss'
import { NotionSnippetElement } from '../../../NotionSnippet/types'

export default function Top(): JSX.Element {
    return (
        <section id={'about'} className={styles.topSection}>
            <div className={styles.containerWrapper}>
                <div className={globalStyles.container}>
                    <div className={styles.content}>
                        <NotionSnippet code={'page-header'}>
                            {(props: NotionSnippetElement) => (
                                <>
                                    <h1
                                        className={styles.content__title}
                                        dangerouslySetInnerHTML={{
                                            __html: props.header,
                                        }}
                                    />
                                    <p
                                        className={styles.content__description}
                                        dangerouslySetInnerHTML={{
                                            __html: props.content,
                                        }}
                                    />
                                </>
                            )}
                        </NotionSnippet>
                    </div>
                </div>
            </div>
        </section>
    )
}
