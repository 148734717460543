import React from 'react'

import styles from './styles.module.scss'
import Image from '../../../Image'
import NotionSnippet from '../../../NotionSnippet'
import globalStyles from '../../styles/styles.module.scss'
import { NotionSnippetElement } from '../../../NotionSnippet/types'

export default function Reasons(): JSX.Element {
    const data = [
        {
            image: '/assets/icons/r-shielding.svg',
            title: 'Полное экранирование от клиента',
            templateCode: 'reasons-block-{{id}}',
            width: 24,
            height: 24,
        },
        {
            image: '/assets/icons/r-channel.svg',
            title: 'Стабильный канал сбыта',
            templateCode: 'reasons-block-{{id}}',
            width: 18,
            height: 18,
        },
        {
            image: '/assets/icons/r-economy.svg',
            title: 'Экономия расходов на маркетинге',
            templateCode: 'reasons-block-{{id}}',
            width: 27,
            height: 18,
        },
        {
            image: '/assets/icons/r-rise.svg',
            title: 'Рост продаж по комулятивному эффекту',
            templateCode: 'reasons-block-{{id}}',
            width: 29,
            height: 17,
        },
        {
            image: '/assets/icons/r-documents.svg',
            title: 'Упрощение документооборота',
            templateCode: 'reasons-block-{{id}}',
            width: 18,
            height: 20,
        },
        {
            image: '/assets/icons/r-logistic.svg',
            title: 'Логистика на нас',
            templateCode: 'reasons-block-{{id}}',
            width: 22,
            height: 22,
        },
        {
            image: '/assets/icons/r-risk.svg',
            title: 'Больше прозрачности, меньше рисков',
            templateCode: 'reasons-block-{{id}}',
            width: 24,
            height: 17,
        },
        {
            image: '/assets/icons/r-person.svg',
            title: 'Берем физлиц на себя',
            templateCode: 'reasons-block-{{id}}',
            width: 18,
            height: 18,
        },
        {
            image: '/assets/icons/r-shipment.svg',
            title: 'Ускоренные отгрузки по фин. гарантиям',
            templateCode: 'reasons-block-{{id}}',
            width: 18,
            height: 18,
        },
    ]

    return (
        <section className={styles.reasons}>
            <div className={globalStyles.container}>
                <NotionSnippet code={'reasons-title'}>
                    {(props: NotionSnippetElement) => (
                        <h2
                            className={styles.reasonsTitle}
                            dangerouslySetInnerHTML={{ __html: props.header }}
                        />
                    )}
                </NotionSnippet>
                <div className={styles.row}>
                    {data.map((i, idx) => (
                        <NotionSnippet
                            key={`${i.templateCode.replace(
                                '{{id}}',
                                `${idx + 1}`
                            )}`}
                            code={`${i.templateCode.replace(
                                '{{id}}',
                                `${idx + 1}`
                            )}`}
                        >
                            {(props: NotionSnippetElement) => (
                                <div key={`${idx}`} className={styles.rowItem}>
                                    <div className={styles.rowItemOuter}>
                                        <div
                                            className={styles.rowItemOuterLeft}
                                        >
                                            <div
                                                className={
                                                    styles.rowItemOuterLeftBackground
                                                }
                                            >
                                                <Image
                                                    width={i.width}
                                                    height={i.height}
                                                    alt={i.title}
                                                    src={i.image}
                                                    hideBlur={true}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={styles.rowItemOuterRight}
                                        >
                                            <div
                                                className={
                                                    styles.rowItemOuterRightTitle
                                                }
                                                dangerouslySetInnerHTML={{
                                                    __html: props.header,
                                                }}
                                            />
                                            <div
                                                className={
                                                    styles.rowItemOuterRightDescription
                                                }
                                                dangerouslySetInnerHTML={{
                                                    __html: props.content,
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </NotionSnippet>
                    ))}
                </div>
            </div>
        </section>
    )
}
