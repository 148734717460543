import Head from 'next/head'
import * as React from 'react'
import { SnippetsContext } from 'src/context/snippets-context'

import NotionSnippet from '../src/components/NotionSnippet'
import { type NotionSnippetElement } from '../src/components/NotionSnippet/types'
import HomePage from '../src/containers/HomePage'
import { type IDatabase } from '@/lib/types'
import { getRedisData } from '@/lib/providers/getRedisData'

interface Props {
    database: IDatabase | null
    snippets: NotionSnippetElement[]
}

export default function DomainPage({ database, snippets }: Props): JSX.Element {
    return (
        <SnippetsContext.Provider value={snippets}>
            <NotionSnippet code={'meta-description'}>
                {description =>
                    description && (
                        <Head>
                            <meta
                                name="description"
                                content={description.content}
                            />
                            <meta
                                property="og:description"
                                content={description.content}
                            />
                            <meta
                                name="twitter:description"
                                content={description.content}
                            />
                        </Head>
                    )
                }
            </NotionSnippet>
            <NotionSnippet code={'meta-keywords'}>
                {keywords =>
                    keywords && (
                        <Head>
                            <meta name="keywords" content={keywords.content} />
                        </Head>
                    )
                }
            </NotionSnippet>
            <NotionSnippet code={'meta-title'}>
                {title =>
                    title && (
                        <Head>
                            <meta property="og:title" content={title.content} />
                            <meta
                                name="twitter:title"
                                content={title.content}
                            />
                            <title>{title.content}</title>
                        </Head>
                    )
                }
            </NotionSnippet>
            <HomePage database={database} />
        </SnippetsContext.Provider>
    )
}

export async function getServerSideProps(): Promise<{ props: Props }> {
    const database = await getRedisData<IDatabase>('database')
    const snippets = await getRedisData<NotionSnippetElement[]>('snippets')

    return {
        props: {
            database,
            snippets,
        },
    }
}
